<template>
  <div
    :class="{
      content: true,
      page: page.total > queryParams.Limit,
    }"
    ref="content"
  >
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form>
          <a-row>
            <a-col :sm="6" :xxl="4">
              <e-input
                title="名称"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.Name"
              />
            </a-col>
            <a-col :sm="6" :xxl="4">
              <e-select
                title="类型"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.Type"
                :options="options"
              />
            </a-col>
            <a-col :sm="5" :xxl="3">
              <e-radio
                title="状态"
                :labelCol="6"
                :valueCol="16"
                v-model:value="queryParams.IsOn"
                :options="[
                  {
                    label: '下架',
                    value: false,
                  },
                  {
                    label: '上架',
                    value: true,
                  },
                ]"
              />
            </a-col>

            <a-col :sm="5" :xxl="3">
              <e-radio
                title="必选"
                :labelCol="6"
                :valueCol="16"
                v-model:value="queryParams.IsMust"
                :options="[
                  {
                    label: '否',
                    value: false,
                  },
                  {
                    label: '是',
                    value: true,
                  },
                ]"
              />
            </a-col>

            <a-col>
              <a-button type="primary" @click="getDataList(1)"> 查询 </a-button>
              <a-button
                type="primary"
                @click="reset(() => resetFun())"
                danger
                style="margin-left: 10px"
              >
                重置
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="data-list">
        <a-row :gutter="[16, 20]">
          <a-col :sm="6" :xxl="4" v-for="(v, k) in list" :key="k">
            <a-card hoverable>
              <template #cover>
                <div class="image">
                  <img alt="example" :src="$filters.image(v.pic)" />
                </div>
              </template>
              <template #actions>
                <a-switch
                  checked-children="上架"
                  un-checked-children="下架"
                  v-model:checked="v.isOn"
                  @change="changeStatus(v.id, $event)"
                />
                <a-tooltip>
                  <template #title>编辑</template>
                  <a @click.stop="$refs.goodsDish.open(v)">
                    <EditOutlined />
                  </a>
                </a-tooltip>
                <a-tooltip>
                  <template #title>删除</template>
                  <a @click.stop="delForm(() => delFormFun(v))">
                    <DeleteOutlined />
                  </a>
                </a-tooltip>
              </template>
              <a-card-meta>
                <template #title>
                  <div>
                    {{ v.name }}
                  </div>
                </template>
                <template #description>
                  <div>
                    <a-tag color="blue">{{
                      ["菜品", "台位费", "前厅商品", "损耗"][v.type]
                    }}</a-tag>
                    <div>售价：¥{{ v.sellingPrice }}</div>
                    <div>
                      库存：{{ v.stockQuantity }}

                      <a-tooltip>
                        <template #title>修改库存</template>
                        <a
                          @click.stop="
                            $refs.dishQuantity.open(
                              v.name,
                              v.id,
                              v.stockQuantity
                            )
                          "
                        >
                          <EditOutlined />
                        </a>
                      </a-tooltip>
                    </div>
                  </div>
                </template>
              </a-card-meta>
            </a-card>
          </a-col>
        </a-row>
      </div>
    </div>

    <div class="pagination">
      <a-pagination
        :total="page.total"
        v-model:current="page.page"
        v-model:page-size="queryParams.Limit"
        :hide-on-single-page="true"
        :show-size-changer="true"
        :page-size-options="['20', '40', '100', '200']"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      >
        <template #itemRender="{ page, type }">
          <a v-if="type === 'prev'" class="ant-pagination-item-link">上一页</a>
          <a v-else-if="type === 'next'" class="ant-pagination-item-link">
            下一页
          </a>
          <span v-else>{{ page }}</span>
        </template>
      </a-pagination>
      <span>数据总数：{{ page.total }}</span>
    </div>

    <EChangeNum
      ref="eChangeNum"
      title="修改价格"
      label="价格"
      @ok="onChangePrice"
    />
    <GoodsDish ref="goodsDish" @ok="getDataList(page.page)" />
    <DishQuantity ref="dishQuantity" @ok="setDishStockQuantity" />
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import {
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue'
import { DishClass } from '@/apis/dish'
import page from '@/mixins/page'
import EChangeNum from '@/components/forms/ChangeNum'
import GoodsDish from '@/components/forms/dish/GoodsDish'
import DishQuantity from '@/components/forms/dish/DishQuantity'

const api = new DishClass()
export default defineComponent({
  mixins: [page],
  components: {
    EditOutlined,
    DeleteOutlined,
    EChangeNum,
    GoodsDish,
    DishQuantity
  },
  setup () {
    const loading = ref(false)
    const height = ref(500)
    const width = ref(500)

    const dish = ref()

    const queryParams = ref({
      Limit: 24,
      IsOn: true
    })

    return {
      queryParams,
      loading,
      height,
      width,
      dish,
      options: [
        {
          label: '菜品',
          value: 0
        },
        {
          label: '台位费',
          value: 1
        },
        {
          label: '前厅商品',
          value: 2
        },
        {
          label: '损耗',
          value: 3
        }
      ]
    }
  },
  created () {
    this.getDataList(1)
  },
  methods: {
    setStockQuantity () {
      const storeId = this.$store.state.user.userInfo.storeId
      api.setStockQuantity(storeId).then(() => {
        this.$message.success('设置成功')
        this.getDataList(this.page.page)
      })
    },
    setDishStockQuantity (e) {
      const { dishId, stockQuantity } = e
      api.setDishStockQuantity(dishId, stockQuantity).then(() => {
        this.$message.success('设置成功')
        this.$refs.dishQuantity.onClose()
        this.getDataList(this.page.page)
      })
    },
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit
      q.IsNotDish = true
      api.gets(q).then((resp) => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
        this.getTableHeight()
      })
    },
    resetFun () {
      this.queryParams = {
        Limit: 24
      }

      this.getDataList(1)
    },
    delFormFun (record) {
      const _this = this
      _this.$confirm({
        title: '确定删除?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          `即将删除数据：${record.name}`
        ),

        onOk () {
          api.del(record.id).then(() => {
            _this.$message.success('删除成功！')
            _this.getDataList(1)
          })
        }
      })
    },

    changeStatus (id, state) {
      api.state(id, state).then(() => {
        this.$message.success('修改成功')
        this.getDataList(this.page.page)
      })
    },
    changeIsMust (id, state) {
      api.setMust(id, state).then(() => {
        this.$message.success('修改成功')
        this.getDataList(this.page.page)
      })
    },
    onChangePrice (e) {
      api
        .setPrice({
          id: this.dish.id,
          price: e
        })
        .then(() => {
          this.$message.success('修改成功')
          this.$refs.eChangeNum.handleClose()
          this.getDataList(this.page.page)
        })
    },
    showChangePrice (record) {
      this.dish = record
      this.$refs.eChangeNum.open(record.sellingPrice)
    },

    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    }
  }
})
</script>

<style lang="less" scoped>
.image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
  }
}
</style>
